.App {
  font-family: 'Trebuchet MS';
  text-align: center;
  overflow-x: hidden;
}

.Summary {
  text-align: justify;
  margin-left: 10px;
  margin-right: 10px;
}

.TimelineTitle {
  text-align: left;
}

.selfEmployed {
  font-style: normal;
}

.infoSection{
  display: flex;
}

.TechnicalInfo{
  text-align: left;
  flex: 1;
  flex-basis: 40%;
}

.TechnicalInfo img{
  vertical-align:middle;
}

.PersonalInfo{
  text-align: left;
  flex: 1;
  flex-basis: 40%;
}

.PersonalInfo a,.PersonalInfo a:visited{
  color: black;
}

.closeButton {
    font-size: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 5px 0px 0px;
}

.closeButton:hover {
  opacity: 0.7;
}

@media screen and (max-width: 700px) {
  .infoSection {
    flex-direction: column;
  }
  .TechnicalInfo, .PersonalInfo, .PictureOfMe {
    width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .PictureOfMe:hover { 
    transform: translateX(-150px); 
  }
}

.offseted{
  padding-left: 54px;
}

.SeeSource {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
}

.PictureOfMe{
  transition: all .2s ease-in-out;
  margin-right: 10px;
  flex-basis: 20%;
  width: 200px;
}

.SwitchLanguage {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
}

h6 {
  margin-top: 0;
  padding-top: 0; 
  line-height: 1.2;
  font-weight: normal;
  color: gray;
}
